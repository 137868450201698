import { createStore, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk'
import {composeWithDevTools} from "redux-devtools-extension/developmentOnly";

import rootReducer from './reducers'


const middlewares = [];
middlewares.push(thunkMiddleware);

//include the redux-logger middleware only on development environment
if (process.env.NODE_ENV === `development`) {
    const { logger } = require(`redux-logger`);
    middlewares.push(logger);
}


export default function configureStore(preloadedState = undefined) {

    const middlewareEnhancer = applyMiddleware(...middlewares);

    const enhancers = [middlewareEnhancer];

    const composedEnhancers = composeWithDevTools(...enhancers);

    const store = createStore(
        rootReducer,
        preloadedState,
        composedEnhancers
    );

    if (process.env.NODE_ENV !== 'production' && module.hot) {
        module.hot.accept('./reducers', () => store.replaceReducer(rootReducer))
    }
    return store
}