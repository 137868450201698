import {
    ORDER_GET_MENU_BY_GROUP,
    ORDER_GOT_GROUPS, ORDER_GOT_MENU_BY_GROUP,
    ORDER_GOT_SITES, ORDER_RESET_LOGOUT, ORDER_RESET_STATE, ORDER_REVIEW, ORDER_SELECT_GROUP, ORDER_SELECT_PRODUCT,
    ORDER_SELECT_SITE, ORDER_SET_COMMENTS,
    ORDER_STEP_BACK,
    ORDER_STEP_NEXT, ORDER_SUBMIT, ORDER_SUBMIT_FAIL, ORDER_SUBMIT_SUCCESS
} from "../actions/OrderActions";

export const ORDER_TOTAL_STEPS = 5;

const initialState = {
    currentStep: 0,
    isBackEnabled: false,
    selectedSiteID: undefined,
    selectedGroupID: undefined,
    selectedProductID: undefined,
    sites: undefined,
    groups: undefined,
    menu: undefined,
    comments: undefined,
    status: undefined, //can be Submitting, Success or Fail - undefined means during order
    error: undefined
};


function isBackEnabled(step) {
    switch (step)
    {
        case 1:
        case 2:
        case 3:
        case 4:
            return true;

        default:
            return false;
    }
}

export default function orderReducer(state = initialState, action) {
    switch (action.type)
    {
        case ORDER_RESET_STATE:
            return Object.assign({}, state, {
                ...initialState,
            });

        case ORDER_STEP_NEXT:
            let nextStep = state.currentStep + 1;
            if (nextStep >= ORDER_TOTAL_STEPS) nextStep = ORDER_TOTAL_STEPS - 1;

            return Object.assign({}, state, {
                currentStep: nextStep,
                isBackEnabled: isBackEnabled(nextStep),
            });

        case ORDER_STEP_BACK:
            let prevStep = state.currentStep - 1;
            if (prevStep < 0) prevStep = 0;

            return Object.assign({}, state, {
                currentStep: prevStep,
                isBackEnabled: isBackEnabled(prevStep),
            });

        case ORDER_GOT_SITES:
            // action.result contains {sites}
            return Object.assign({}, state, action.result);

        case ORDER_SELECT_SITE:
            // action contains {selectedSiteID}
            return Object.assign({}, state, {
                selectedSiteID: action.selectedSiteID,
                selectedGroupID: undefined,
                selectedProductID: undefined,
                comments: undefined,

                currentStep: 1, //select group
                isBackEnabled: isBackEnabled(1),
            });

        case ORDER_GOT_GROUPS:
            // action.result contains {groups}
            return Object.assign({}, state, action.result);

        case ORDER_SELECT_GROUP:
            // action contains {selectedGroupID}
            return Object.assign({}, state, {
                selectedGroupID: action.selectedGroupID,
                selectedProductID: undefined,
                comments: undefined,

                currentStep: 2, //select product
                isBackEnabled: isBackEnabled(2),
            });

        case ORDER_GET_MENU_BY_GROUP:
            return Object.assign({}, state, {
                menu: undefined
            });

        case ORDER_GOT_MENU_BY_GROUP:
            // action.result contains {menu}
            return Object.assign({}, state, action.result);

        case ORDER_SELECT_PRODUCT:
            // action contains {selectedProductID}
            return Object.assign({}, state, {
                selectedProductID: action.selectedProductID,
                comments: undefined,

                currentStep: 3, //comments
                isBackEnabled: isBackEnabled(3),
            });

        case ORDER_SET_COMMENTS:
            // action contains {comments}
            return Object.assign({}, state, {
                comments: action.comments,
            });

        case ORDER_REVIEW:
            return Object.assign({}, state, {
                status: undefined,
                error: undefined,

                currentStep: 4, //order_review
                isBackEnabled: isBackEnabled(4),
            });

        case ORDER_SUBMIT:
            return Object.assign({}, state, {
                status: 'Submitting'
            });

        case ORDER_SUBMIT_FAIL:
            return Object.assign({}, state, {
                status: 'Fail',
                error: action.error,
            });

        case ORDER_SUBMIT_SUCCESS:
            return Object.assign({}, state, {
                ...initialState,
                status: 'Success',
            });

        case ORDER_RESET_LOGOUT:
            return Object.assign({}, state, initialState);

        default:
            return state;  //unchanged

    }
}