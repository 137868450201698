import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import {connect} from "react-redux";
import {ExitToApp} from "@material-ui/icons";
import logo from "../assets/images/miLunch-web.svg";

class Bar extends Component {

    render() {
        const {
            performingAction,
            theme,
            user,
            onLoginClick,
            onLogoutClick,
            // classes
        } = this.props;


        return (
            <AppBar color="primary" position="fixed">
                <Toolbar variant={theme.dense ? 'dense' : 'regular'}>

                    <Box display="flex" flexGrow={1}>
                        {/*<Typography color="inherit" variant="h6">miLunch</Typography>*/}
                        <img height={32} src={logo} alt="miLunch" />
                    </Box>

                    {user &&
                    <>

                        {/*<Typography mr={2} color="inherit" variant="h6" className={classes.userDisplayName}>{user.displayName}</Typography>*/}

                        <Button color="secondary" disabled={performingAction} variant="contained" onClick={onLogoutClick}>
                            <ExitToApp/>
                        </Button>




                    </>
                    }

                    {!user &&
                    <>
                        <Button color="secondary" disabled={performingAction} variant="contained" onClick={onLoginClick}>Login</Button>
                    </>
                    }
                </Toolbar>
            </AppBar>
        );
    }
}

Bar.defaultProps = {
    performingAction: false
};

Bar.propTypes = {
    performingAction: PropTypes.bool.isRequired,
    theme: PropTypes.object.isRequired,
    onLoginClick: PropTypes.func.isRequired,
    onLogoutClick: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
    const { user } = state;
    return {
        user
    }
}

// const BarWithStyles = withStyles(styles)(Bar);

export default connect(mapStateToProps)(Bar);