import {API_URL} from "../settings";

export const STATIC_MENU_GET_ALL = 'STATIC_MENU_GET_ALL';
export const STATIC_MENU_GOT_ALL = 'STATIC_MENU_GOT_ALL';

function internalGetAll()
{
    return {
        type: STATIC_MENU_GET_ALL,
    }
}

function internalGotAll(result)
{
    return {
        type: STATIC_MENU_GOT_ALL,
        result
    }
}

export function staticMenuGetAll()
{
    return function (dispatch) {

        dispatch(internalGetAll());

        return fetch(`${API_URL}/staticMenu/getAll`, {
            credentials: "include",
        })
            .then((response) =>
                {
                    if (!response.ok) throw new Error('/staticMenu/getAll failed');
                    return response.json();
                }
            )
            .then(result =>
                dispatch(internalGotAll(result))
            )
            .catch(error => {
                dispatch(internalGotAll(null));
                console.log(error);
            })

    }
}
