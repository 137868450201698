import React, { Component } from 'react';

import readingTime from 'reading-time';

import {MuiThemeProvider, withStyles} from '@material-ui/core/styles';

import CssBaseline from '@material-ui/core/CssBaseline';
import Snackbar from '@material-ui/core/Snackbar';

import ErrorBoundary from './components/ErrorBoundary';
import LaunchScreen from './components/LaunchScreen';
import Bar from './components/Bar';
import Router from './components/Router';
import LoginDialogHost from './components/DialogHost';

import Theme from './Theme';
import {authLogout, authWhoAmI} from "./actions/AuthActions";
import {connect} from "react-redux";
import {walletResetLogout} from "./actions/WalletActions";
import {orderResetLogout} from "./actions/OrderActions";


//BASED ON: https://github.com/phoqe/react-material-ui-firebase/tree/master/src

const styles = theme => ({
    anchorOriginBottomCenter: {
        bottom: '67px'
    },
});

class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ready: false,
            performingAction: false,
            theme: Theme,

            loginDialog: {
                open: false
            },

            snackbar: {
                autoHideDuration: 0,
                message: '',
                open: false
            }
        };
    }

    componentDidMount() {

        const { dispatch } = this.props;

        if (window.location.pathname.startsWith('/resetPwd'))
        {
            this.setState({
                ready: true
            });
        }
        else
        {

            dispatch(authWhoAmI())
                .then((result) =>
                {
                    if (result)
                    {
                        this.openSnackbar(`Signed in as ${result.user.displayName}`);
                    } else
                    {
                        this.openDialog('loginDialog');
                    }

                    this.setState({
                        ready: true
                    });
                });

        }
    }

    componentWillUnmount() {

    }


    openDialog = (dialogId, callback) => {
        const dialog = this.state[dialogId];

        if (!dialog || dialog.open === undefined || null) {
            return;
        }

        dialog.open = true;

        this.setState({ dialog }, callback);
    };

    closeDialog = (dialogId, callback) => {
        const dialog = this.state[dialogId];

        if (!dialog || dialog.open === undefined || null) {
            return;
        }

        dialog.open = false;

        this.setState({ dialog }, callback);
    };

    closeAllDialogs = (callback) => {
        this.setState({
            aboutDialog: {
                open: false
            },

            signUpDialog: {
                open: false
            },

            loginDialog: {
                open: false
            },

            settingsDialog: {
                open: false
            },

            deleteAccountDialog: {
                open: false
            },

            signOutDialog: {
                open: false
            }
        }, callback);
    };

    logout = () => {

        const { dispatch } = this.props;

        dispatch(authLogout())
            .then((result) => {
                this.openSnackbar('Logged out');
            });

        dispatch(walletResetLogout());
        dispatch(orderResetLogout());
    };

    openSnackbar = (message, autoHideDuration = 2, callback) => {
        this.setState({
            snackbar: {
                autoHideDuration: readingTime(message).time * autoHideDuration,
                message,
                open: true
            }
        }, () => {
            if (callback && typeof callback === 'function') {
                callback();
            }
        });
    };

    closeSnackbar = (clearMessage = false) => {
        const {
            snackbar
        } = this.state;

        this.setState({
            snackbar: {
                message: clearMessage ? '' : snackbar.message,
                open: false
            }
        });
    };

    render() {
        const {
            ready,
            performingAction,
            theme,
            loginDialog,
            snackbar
        } = this.state;

        const {
            classes
        } = this.props;

        return (
            <MuiThemeProvider theme={theme}>
                <CssBaseline />

                <ErrorBoundary>
                    {!ready &&
                    <LaunchScreen />
                    }

                    {ready &&
                    <>
                        <Bar
                            performingAction={performingAction}
                            theme={theme}
                            onLoginClick={() => this.openDialog('loginDialog')}
                            onLogoutClick={() => this.logout()}
                        />

                        <Router
                            openSnackbar={this.openSnackbar}
                            openDialog={this.openDialog}
                        />

                        <LoginDialogHost
                            dialogs={
                                {
                                    loginDialog: {
                                        dialogProps: {
                                            open: loginDialog.open,

                                            onClose: (callback) => {
                                                this.closeDialog('loginDialog');

                                                if (callback && typeof callback === 'function') {
                                                    callback();
                                                }
                                            }
                                        },

                                        props: {
                                            performingAction: performingAction,

                                            openSnackbar: this.openSnackbar
                                        }
                                    },



                                }
                            }
                        />

                        <Snackbar
                            autoHideDuration={snackbar.autoHideDuration}
                            message={snackbar.message}
                            open={snackbar.open}
                            onClose={this.closeSnackbar}
                            classes={{
                                anchorOriginBottomCenter: classes.anchorOriginBottomCenter
                            }}
                        />
                    </>
                    }
                </ErrorBoundary>
            </MuiThemeProvider>
        );
    }


}

const AppWithStyles = withStyles(styles)(App);

export default connect()(AppWithStyles);