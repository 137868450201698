import {API_URL} from "../settings";

export const WALLET_GET_INFO = 'WALLET_GET_INFO';
export const WALLET_GOT_INFO = 'WALLET_GOT_INFO';
export const WALLET_GET_TRANSACTIONS = 'WALLET_GET_TRANSACTIONS';
export const WALLET_GOT_TRANSACTIONS = 'WALLET_GOT_TRANSACTIONS';
export const WALLET_RESET_LOGOUT = 'WALLET_RESET_LOGOUT';

function internalGetInfo()
{
    return {
        type: WALLET_GET_INFO,
    }
}

function internalGotInfo(walletInfo)
{
    return {
        type: WALLET_GOT_INFO,
        walletInfo
    }
}

export function walletGetInfo()
{
    return function (dispatch) {

        dispatch(internalGetInfo());

        return fetch(`${API_URL}/wallet/info`, {
            credentials: "include",
        })
            .then((response) =>
                {
                    if (!response.ok) throw new Error('wallet get info failed');
                    return response.json();
                }
            )
            .then(walletInfo =>
                dispatch(internalGotInfo(walletInfo))
            )
            .catch(error => {
                dispatch(internalGotInfo(null));
                console.log(error);
            })

    }
}

//--

function internalGetTransactions(startIndex, stopIndex)
{
    return {
        type: WALLET_GET_TRANSACTIONS,
        startIndex,
        stopIndex,
    }
}

function internalGotTransactions(result)
{
    return {
        type: WALLET_GOT_TRANSACTIONS,
        result,
    }
}


export function walletGetTransactions(startIndex, stopIndex)
{
    return function (dispatch) {

        dispatch(internalGetTransactions(startIndex, stopIndex));

        return fetch(`${API_URL}/wallet/transactions?startIndex=${startIndex}&stopIndex=${stopIndex}`, {
            credentials: "include"
        })
            .then((response) =>
                {
                    if (!response.ok) throw new Error('fetch error');
                    return response.json();
                }
            )
            .then(result =>
                dispatch(internalGotTransactions(result))
            )
            .catch(error => {
                console.log(error);
            })

    }
}


export function walletResetLogout()
{
    return {
        type: WALLET_RESET_LOGOUT
    }
}
