import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import {connect} from "react-redux";
import Box from "@material-ui/core/Box";
import {withStyles} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {Form, withFormik} from "formik";
import * as Yup from "yup";
import {authResetPwd} from "../actions/AuthActions";

const styles = theme => ({

    media: {
        height: 270,
    },
    cardContent: {
        padding: '9px 12px',
    },
    [theme.breakpoints.up('sm')]: {
        cardContent: {
            minHeight: '134px'
        }
    },


});


class ScreenResetPwd extends Component {

    render() {

        //formik
        const {
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
        } = this.props;

        return (
            <Box my={4}>

                <Typography variant={'h5'}>
                    Change your password
                </Typography>

                <Box my={3}>

                    <Form onSubmit={handleSubmit}>

                        <Grid container direction="column" spacing={2}>

                            <Grid item xs>
                                <TextField
                                    id={'password'}
                                    name={'password'}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.password}
                                    autoComplete="new-password"
                                    disabled={isSubmitting}
                                    error={!!(touched.password && errors.password)}
                                    helperText={(touched.password && errors.password) ? errors.password : ''}
                                    label="Password"
                                    placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
                                    type="password"
                                    variant="outlined"
                                />
                            </Grid>

                            <Grid item xs>
                                <TextField
                                    id={'password2'}
                                    name={'password2'}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.password2}
                                    autoComplete="new-password"
                                    disabled={isSubmitting}
                                    error={!!(touched.password2 && errors.password2)}
                                    helperText={(touched.password2 && errors.password2) ? errors.password2 : ''}
                                    label="Confirm"
                                    placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
                                    type="password"
                                    variant="outlined"
                                />
                            </Grid>

                        </Grid>

                        <Box my={3}>

                            <Button
                                type={'submit'}
                                color="primary"
                                disabled={isSubmitting}
                                variant="contained"
                            >Submit</Button>

                        </Box>

                    </Form>

                </Box>


            </Box>
        );
    }


}

ScreenResetPwd.propTypes = {
    user: PropTypes.object
};

const ScreenResetPwdWithFormik = withFormik({
    mapPropsToValues: () => ({
        password: '',
        password2: '',
    }),

    validationSchema: Yup.object({
        password: Yup.string()
            .min(8, 'Too Short')
            .max(50, 'Too Long')
            .required('Required'),
        password2: Yup.string()
            .min(8, 'Too Short')
            .max(50, 'Too Long')
            .required('Required')
            .oneOf([Yup.ref('password'), null], 'Passwords must match'),
    }),

    handleSubmit: (values, { props, setSubmitting }) => {

        const {
            dispatch,
            openSnackbar,
            openDialog
        } = props;

        //withRouter
        const { match, history } = props;

        dispatch(authResetPwd(match.params.token, values.password))
            .then(result => {

                if (result && result.error)
                {
                    openSnackbar('Password was NOT changed. ' + result.error);
                }
                else
                {
                    openSnackbar('Password has been changed.');
                }

                openDialog('loginDialog');

                history.push('/');

            })
            .finally(() => {
                setSubmitting(false);
            });

    },
})(ScreenResetPwd);

const ScreenResetPwdWithStyles = withStyles(styles)(ScreenResetPwdWithFormik);

const ScreenResetPwdWithRouter = withRouter(ScreenResetPwdWithStyles);

export default connect()(ScreenResetPwdWithRouter);