import React, { Component } from 'react';

import PropTypes from 'prop-types';

import ErrorIcon from '@material-ui/icons/Error';

import EmptyState from './EmptyState';

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);

        this.state = {
            hasError: false,
            eventId: null
        };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        //TODO
        console.log(error, errorInfo);
    }

    render() {
        // Properties
        const {
            children
        } = this.props;

        const {
            hasError
        } = this.state;

        if (hasError) {
            return (
                <EmptyState
                    icon={<ErrorIcon />}
                    title="Something went wrong"
                />
            );
        }

        return children;
    }
}

ErrorBoundary.propTypes = {
    // Properties
    children: PropTypes.array.isRequired
};

export default ErrorBoundary;