import React, { Component } from 'react';

import EmptyState from './EmptyState';
import {connect} from "react-redux";
import {withStyles} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import {orderGetGroups, orderSelectGroup} from "../actions/OrderActions";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import {RemoveCircle} from "@material-ui/icons";
import {blueGrey, red} from "@material-ui/core/colors";
import Skeleton from "@material-ui/lab/Skeleton";



const styles = theme => ({

});


class OrderSelectGroup extends Component {


    componentDidMount() {
        const { dispatch, order } = this.props;

        if (order && order.selectedSiteID)
        {
            dispatch(orderGetGroups(order.selectedSiteID));
        }
    }

    render() {

        //redux
        const {
            dispatch,
            user,
            order,
        } = this.props;

        //withStyles
        // const { classes } = this.props;

        const handleGroupClick = (e, groupID) => {
            dispatch(orderSelectGroup(groupID));
        };


        if (user) {

            if (order && order.groups && order.groups.length > 0)
            {
                return (
                    <Box display={'flex'} flexWrap={'wrap'} m={-1}>

                        {
                            order.groups.map( (group, idx) =>
                                <Box key={group.id} flexGrow={1} flexBasis={ idx === 0 ? '100%' : 0 } minWidth={'300px'} m={1}>
                                        <Card>
                                            <CardActionArea onClick={(e) => handleGroupClick(e, group.id)} disabled={group.status !== "Open"}>
                                                <CardContent>

                                                    <Box display={'flex'} justifyContent={'space-between'}>

                                                        <Typography variant="h5" component="h2">
                                                            <Box color={group.status === "Open" ? 'initial' : blueGrey["300"]}>
                                                                {group.name}
                                                            </Box>
                                                        </Typography>


                                                        {group.status !== "Open" &&
                                                            <Box display={'flex'} alignItems={'center'}>
                                                                <Box component={'span'} color={red["800"]} mr={1}>
                                                                    <RemoveCircle />
                                                                </Box>
                                                                <Typography variant="body2" color="textSecondary" component="span">
                                                                    {group.status}
                                                                </Typography>
                                                            </Box>
                                                        }
                                                    </Box>


                                                </CardContent>
                                            </CardActionArea>
                                        </Card>
                                </Box>
                            )
                        }

                    </Box>
                );
            }
            else {
                const skeletons = [];
                for (let i = 0; i < 5; i++) {
                    skeletons.push(
                        <Box key={i} flexGrow={1} flexBasis={ i === 0 ? '100%' : 0 } minWidth={'300px'} m={2}>
                            <Skeleton variant={'text'} height={46} />
                        </Box>
                    );
                }
                return (
                    <Box display={'flex'} flexWrap={'wrap'} m={-2}>
                        { skeletons }
                    </Box>
                )
            }

        }

        return (
            <EmptyState />
        );
    }


}


function mapStateToProps(state) {
    const { user, order } = state;
    return {
        user, order
    };
}

const OrderSelectGroupWithStyles = withStyles(styles)(OrderSelectGroup);

export default connect(mapStateToProps)(OrderSelectGroupWithStyles);