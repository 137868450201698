import {STATIC_MENU_GOT_ALL} from "../actions/StaticMenuActions";

const initialState = {
    staticMenuImages: undefined
};

export default function staticMenuReducer(state = initialState, action) {
    switch (action.type)
    {

        case STATIC_MENU_GOT_ALL:
            // action.result contains {staticMenuImages}
            return Object.assign({}, state, action.result);


        default:
            return state;  //unchanged

    }
}