import React, { Component } from 'react';

import PropTypes from 'prop-types';

import Hidden from '@material-ui/core/Hidden';

import AlertDialog from './AlertDialog';
import LoginDialog from "./LoginDialog";

class DialogHost extends Component {
    render() {
        // Properties
        const {
            user,
            dialogs
        } = this.props;

        const loginDialog = dialogs.loginDialog;
        const signOutDialog = dialogs.signOutDialog;

        return (
            <>
                {user &&
                <>
                    <AlertDialog
                        dialogProps={signOutDialog.dialogProps}

                        {...signOutDialog.props}
                    />
                </>
                }

                <Hidden xsDown>
                    {!user &&
                    <>
                        <LoginDialog
                            dialogProps={loginDialog.dialogProps}
                            {...loginDialog.props}
                        />
                    </>
                    }
                </Hidden>

                <Hidden smUp>
                    {!user &&
                    <>
                        <LoginDialog
                            dialogProps={{
                                fullScreen: true,

                                ...loginDialog.dialogProps
                            }}

                            {...loginDialog.props}
                        />
                    </>
                    }
                </Hidden>


            </>
        );
    }
}

DialogHost.propTypes = {
    // Properties
    user: PropTypes.object,
    dialogs: PropTypes.object.isRequired
};

export default DialogHost;