import React, { Component } from 'react';


import EmptyState from './EmptyState';
import {connect} from "react-redux";
import {withStyles} from "@material-ui/core";
import {orderResetState, orderReview} from "../actions/OrderActions";

import '../assets/styles/swal-icons.css'
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";



const styles = theme => ({
    actionButton: {
        width: '100%',
        marginTop: '50px',
    }
});


class OrderStatus extends Component {



    render() {

        //redux
        const {
            dispatch,
            user,
            order,
        } = this.props;

        //withStyles
        const { classes } = this.props;


        const handleNewOrderClick = () => {
            dispatch(orderResetState());
        };

        const handleBackClick = () => {
            dispatch(orderReview());
        };

        if (user) {

            if (order)
            {
                if (order.status === 'Submitting')
                {
                    return (
                        <Box my={8} align={'center'}>
                            <CircularProgress />
                        </Box>
                    );
                }
                else if (order.status === 'Success')
                {
                    return (
                        <>

                            <Box mb={6}>

                                <Box my={5}>
                                    <div className="swal2-icon swal2-success swal2-animate-success-icon"
                                         style={{display: 'flex'}}>
                                        <div className="swal2-success-circular-line-left"
                                             style={{backgroundColor: 'rgb(255, 255, 255)'}}/>
                                        <span className="swal2-success-line-tip"/>
                                        <span className="swal2-success-line-long"/>
                                        <div className="swal2-success-ring"/>
                                        <div className="swal2-success-fix" style={{backgroundColor: 'rgb(255, 255, 255)'}}/>
                                        <div className="swal2-success-circular-line-right" style={{backgroundColor: 'rgb(255, 255, 255)'}}/>
                                    </div>
                                </Box>

                                <Typography align={'center'} variant="h4" gutterBottom>
                                    Order Submitted
                                </Typography>

                                <Button size={'large'} className={classes.actionButton} variant="outlined" color="primary" onClick={handleNewOrderClick}>
                                    Start Another Order
                                </Button>


                            </Box>
                        </>
                    );
                }
                else if (order.status === 'Fail')
                {
                    return (

                        <>

                            <Box mb={6}>

                                <Box my={5}>
                                    <div className="swal2-icon swal2-error swal2-animate-error-icon" style={{display: 'flex'}}>
                                      <span className="swal2-x-mark">
                                          <span className="swal2-x-mark-line-left"/>
                                          <span className="swal2-x-mark-line-right"/>
                                      </span>
                                    </div>
                                </Box>

                                <Typography align={'center'} variant="h4" gutterBottom>
                                    Order Failed
                                </Typography>

                                <Typography align={'center'} variant="subtitle2" gutterBottom>
                                    {order.error}
                                </Typography>

                                <Button size={'large'} className={classes.actionButton} variant="outlined" color="primary" onClick={handleBackClick}>
                                    Back to Order
                                </Button>

                            </Box>
                        </>

                    );
                }
            }

        }

        return (
            <EmptyState />
        );
    }


}

function mapStateToProps(state) {
    const { user, order } = state;
    return {
        user, order
    };
}

const OrderStatusWithStyles = withStyles(styles)(OrderStatus);

export default connect(mapStateToProps)(OrderStatusWithStyles);