import React, { Component } from 'react';

import moment from "moment";
import PropTypes from 'prop-types';

import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Skeleton from "@material-ui/lab/Skeleton";
import EmptyState from './EmptyState';

import {connect} from "react-redux";
import {withRouter } from 'react-router-dom';
import {withStyles} from "@material-ui/core";
import {PersonOutline} from "@material-ui/icons";
import {AutoSizer, InfiniteLoader, List, WindowScroller} from "react-virtualized";
import {WalletTransaction} from "./WalletTransaction";
import {grey} from "@material-ui/core/colors";
import {walletGetInfo, walletGetTransactions} from "../actions/WalletActions";
import {MSG_NOT_LOGGED_IN} from "../settings";
import Button from "@material-ui/core/Button";


const styles = theme => ({
    buttonActivity: {
        margin: theme.spacing(1),
    },
    userIcon: {
        verticalAlign: 'middle'
    },
    list: {
        outline: 'none'
    }
});

class ScreenWallet extends Component {


    componentDidMount() {

        const { dispatch, user } = this.props;

        if (user)
        {
            dispatch(walletGetInfo());

            dispatch(walletGetTransactions(0, 25));
        }
    }

    render() {

        //redux
        const {
            dispatch,
            user,
            wallet,
        } = this.props;

        //withStyles
        const { classes } = this.props;

        function isRowLoaded ({ index }) {
            return !!wallet.transactions[index];
        }

        function loadMoreRows ({ startIndex, stopIndex }) {

            // return dispatch(walletGetTransactions(startIndex, startIndex+2));
            return dispatch(walletGetTransactions(startIndex, stopIndex));
        }

        function rowRenderer({key, index, isScrolling, isVisible, style}) {

            let row = wallet.transactions[index];

            if (row)
            {
                let strAmount = '';
                if (row.amount < 0)
                {
                    strAmount = '\u2013 \u20AC' + Math.abs(row.amount).toFixed(2);
                }
                else if (row.amount > 0)
                {
                    strAmount = '+ \u20AC' + row.amount.toFixed(2);
                }
                else
                {
                    strAmount = '+ \u20AC0.00';
                }

                return (
                    //use the record id as key to calculate invalidations correctly
                    <WalletTransaction key={row.id} style={style}
                                       date={moment.utc(row.createdDateTime).format('D MMM YYYY HH:mm')}
                                       heading={row.narrative}
                                       detail={row.detail}
                                       amount={strAmount}
                    />
                );
            }


            return (
                //use the key provided by InfiniteLoader
                <WalletTransaction key={key} style={style}
                                   date={<Skeleton variant="text" width={90} height={15}/>}
                                   heading={<Skeleton variant="text" width={50} height={25} />}
                                   detail={<Skeleton variant="text" width={160} height={15}/>}
                                   amount={<Skeleton variant="text" width={50} height={30} />}
                />
            );
        }

        if (user) {
            return (
                <>

                    <Box p={1}>

                        <Box textAlign={'center'} mb={4} mt={2}>
                            <Box component={'span'} mr={1} className={classes.userIcon}>
                                <PersonOutline />
                            </Box>
                            <Typography variant="h5" component="span" gutterBottom>
                                {user.displayName}
                            </Typography>
                        </Box>

                        {
                            wallet && wallet.walletBalance &&
                            <>
                                <Box textAlign={'center'}>
                                    <Typography variant="button" component="div" color={'primary'}>
                                        <Box lineHeight={1}>
                                            Wallet Balance
                                        </Box>
                                    </Typography>
                                </Box>

                                <Box textAlign={'center'} mb={3}>
                                    <Typography variant="h1" component="div" gutterBottom>
                                        &euro;{ (+(wallet.walletBalance)).toFixed(2) }
                                    </Typography>
                                </Box>
                            </>
                        }

                        {user.isEnableTopup &&
                            <Box textAlign={'center'} mb={5} mt={0}>
                                <Button href={"https://form.jotformeu.com/201470802050339"} target={"_blank"} variant="contained" color="primary">
                                    Request Topup
                                </Button>
                            </Box>
                        }

                        <Box textAlign={'center'} my={2}>
                            <Typography variant="button" display="block" color={'primary'} gutterBottom>
                                <Box lineHeight={1}>
                                    Activity
                                </Box>
                            </Typography>
                        </Box>

                        < Box borderBottom={1} color={grey["A100"]}/>

                        <AutoSizer disableHeight>
                            {({width}) => (

                                <WindowScroller>
                                    {({height, isScrolling, onChildScroll, scrollTop}) => (

                                        <InfiniteLoader isRowLoaded={isRowLoaded} loadMoreRows={loadMoreRows}
                                                        rowCount={wallet.totalTransactions}>
                                            {({onRowsRendered, registerChild}) => (

                                                <List
                                                    className={classes.list}
                                                    autoHeight
                                                    height={height}
                                                    onRowsRendered={onRowsRendered}
                                                    ref={registerChild}
                                                    isScrolling={isScrolling}
                                                    onScroll={onChildScroll}
                                                    rowCount={wallet.totalTransactions}
                                                    rowHeight={78}
                                                    rowRenderer={rowRenderer}
                                                    scrollTop={scrollTop}
                                                    width={width}
                                                />

                                            )}
                                        </InfiniteLoader>

                                    )}
                                </WindowScroller>

                            )}
                        </AutoSizer>

                        {
                            wallet && wallet.totalTransactions === 0 &&
                            <>
                                <Box textAlign={'center'} mt={1} mb={3}>
                                    <Typography variant="caption" component="div" gutterBottom>
                                        No Transactions
                                    </Typography>
                                </Box>
                            </>
                        }


                </Box>




                </>
            );
        }

        return (
            <EmptyState description={MSG_NOT_LOGGED_IN} />
        );
    }


}

ScreenWallet.propTypes = {
    user: PropTypes.object
};

function mapStateToProps(state) {
    const { user, wallet } = state;
    return {
        user, wallet
    };
}

const ScreenWalletWithStyles = withStyles(styles)(ScreenWallet);

const ScreenWalletWithRouter = withRouter(ScreenWalletWithStyles);

export default connect(mapStateToProps)(ScreenWalletWithRouter);