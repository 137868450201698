import {AUTH_BYE, AUTH_FAIL, AUTH_SUCCESS} from "../actions/AuthActions";


export default function userReducer(state = null, action) {
    switch (action.type)
    {
        case AUTH_SUCCESS:
            return Object.assign({}, state, action.user);

        case AUTH_FAIL:
        case AUTH_BYE:
            return null;

        default:
            return state;

    }
}