import React, { Component } from 'react';


import EmptyState from './EmptyState';
import {connect} from "react-redux";
import {withStyles} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import {orderGetSites, orderSelectSite} from "../actions/OrderActions";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import {CheckCircle, RemoveCircle} from "@material-ui/icons";
import {blueGrey, green, red} from "@material-ui/core/colors";
import Skeleton from "@material-ui/lab/Skeleton";



const styles = theme => ({

});


class OrderSelectSite extends Component {


    componentDidMount() {
        const { dispatch } = this.props;

        dispatch(orderGetSites());
    }

    render() {

        //redux
        const {
            dispatch,
            user,
            order,
        } = this.props;

        //withStyles
        // const { classes } = this.props;


        const handleSiteClick = (e, siteID) => {
            dispatch(orderSelectSite(siteID));
        };


        if (user) {

            if (order && order.sites && order.sites.length > 0)
            {
                return (
                    <Box display={'flex'} flexWrap={'wrap'} m={-1}>

                        {
                            order.sites.map( site =>
                                <Box key={site.id} flexGrow={1} flexBasis={0} minWidth={'300px'} m={1}>
                                        <Card>
                                            <CardActionArea onClick={(e) => handleSiteClick(e, site.id)} disabled={site.status !== "Open"}>
                                                <CardContent>
                                                    <Typography gutterBottom variant="h5" component="h2">
                                                        <Box color={site.status === "Open" ? 'initial' : blueGrey["300"]}>
                                                            {site.name}
                                                        </Box>
                                                    </Typography>

                                                    <Box display={'flex'} alignItems={'center'}>
                                                        {site.status === "Open"
                                                            ?
                                                            (
                                                                <Box component={'span'} color={green["800"]} mr={1}>
                                                                    <CheckCircle />
                                                                </Box>
                                                            )
                                                            :
                                                            (
                                                                <Box component={'span'} color={red["800"]} mr={1}>
                                                                    <RemoveCircle />
                                                                </Box>
                                                            )
                                                        }
                                                        <Typography variant="body2" color="textSecondary" component="span">
                                                            {site.status}
                                                        </Typography>
                                                    </Box>
                                                </CardContent>
                                            </CardActionArea>
                                        </Card>
                                </Box>
                            )
                        }

                    </Box>
                );
            }
            else {
                return (
                    <Box display={'flex'} flexWrap={'wrap'} m={-2}>
                        <Box flexGrow={1} flexBasis={0} minWidth={'300px'} m={2}>
                            <Skeleton variant={'text'} height={94} />
                        </Box>
                        <Box flexGrow={1} flexBasis={0} minWidth={'300px'} m={2}>
                            <Skeleton variant={'text'} height={94} />
                        </Box>
                    </Box>
                )
            }

        }

        return (
            <EmptyState />
        );
    }


}

function mapStateToProps(state) {
    const { user, order } = state;
    return {
        user, order
    };
}

const OrderSelectSiteWithStyles = withStyles(styles)(OrderSelectSite);

export default connect(mapStateToProps)(OrderSelectSiteWithStyles);