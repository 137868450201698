import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import EmptyState from './EmptyState';
import {connect} from "react-redux";
import {staticMenuGetAll} from "../actions/StaticMenuActions";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import {CardHeader, withStyles} from "@material-ui/core";
import {MSG_NOT_LOGGED_IN} from "../settings";

const styles = theme => ({

    media: {
        height: 270,
    },
    cardContent: {
        padding: '9px 12px',
    },
    [theme.breakpoints.up('sm')]: {
        cardContent: {
            minHeight: '134px'
        }
    },


});


class ScreenMenu extends Component {

    componentDidMount() {

        const { dispatch } = this.props;

        dispatch(staticMenuGetAll());

    }

    render() {

        //redux
        const {
            user,
            staticMenu
        } = this.props;

        //withStyles
        const { classes } = this.props;

        if (user) {

            if (staticMenu && staticMenu.staticMenuImages && staticMenu.staticMenuImages.length > 0)
            {
                return (
                    <Box display={'flex'} flexWrap={'wrap'} mx={-1} my={2}>

                        {
                            staticMenu.staticMenuImages.map((menuItem, idx) =>

                                <Box key={menuItem.id} flexGrow={1} flexBasis={0} minWidth={'310px'} maxWidth={'433px'} m={1}>
                                    <Card>
                                        <CardHeader className={classes.cardContent}
                                                    title={menuItem.productGroup.name}
                                                    subheader={menuItem.caption}
                                        />

                                        {menuItem.imageUrl &&
                                        <CardMedia
                                            className={classes.media}
                                            image={menuItem.imageUrl}
                                            title={menuItem.productName}
                                        />
                                        }


                                    </Card>
                                </Box>

                            )
                        }

                    </Box>
                );
            }
            else
            {
                return (
                    <EmptyState />
                );
            }
        }

        return (
            <EmptyState description={MSG_NOT_LOGGED_IN} />
        );
    }


}

ScreenMenu.propTypes = {
    user: PropTypes.object
};

function mapStateToProps(state) {
    const { user, staticMenu } = state;
    return {
        user,
        staticMenu
    };
}

const ScreenMenuWithStyles = withStyles(styles)(ScreenMenu);

const ScreenMenuWithRouter = withRouter(ScreenMenuWithStyles);

export default connect(mapStateToProps)(ScreenMenuWithRouter) ;