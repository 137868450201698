import {API_URL} from "../settings";

export const ORDER_RESET_STATE = 'ORDER_RESET_STATE';
export const ORDER_STEP_NEXT = 'ORDER_STEP_NEXT';
export const ORDER_STEP_BACK = 'ORDER_STEP_BACK';
export const ORDER_GET_SITES = 'ORDER_GET_SITES';
export const ORDER_GOT_SITES = 'ORDER_GOT_SITES';
export const ORDER_SELECT_SITE = 'ORDER_SELECT_SITE';
export const ORDER_GET_GROUPS = 'ORDER_GET_GROUPS';
export const ORDER_GOT_GROUPS = 'ORDER_GOT_GROUPS';
export const ORDER_SELECT_GROUP = 'ORDER_SELECT_GROUP';
export const ORDER_GET_MENU_BY_GROUP = 'ORDER_GET_MENU_BY_GROUP';
export const ORDER_GOT_MENU_BY_GROUP = 'ORDER_GOT_MENU_BY_GROUP';
export const ORDER_SELECT_PRODUCT = 'ORDER_SELECT_PRODUCT';
export const ORDER_SET_COMMENTS = 'ORDER_SET_COMMENTS';
export const ORDER_REVIEW = 'ORDER_REVIEW';
export const ORDER_SUBMIT = 'ORDER_SUBMIT';
export const ORDER_SUBMIT_SUCCESS = 'ORDER_SUBMIT_SUCCESS';
export const ORDER_SUBMIT_FAIL = 'ORDER_SUBMIT_FAIL';
export const ORDER_RESET_LOGOUT = 'ORDER_RESET_LOGOUT';

export function orderResetState()
{
    return {
        type: ORDER_RESET_STATE
    }
}

export function orderStepNext()
{
    return {
        type: ORDER_STEP_NEXT
    }
}

export function orderStepBack()
{
    return {
        type: ORDER_STEP_BACK
    }
}

//--

function internalGetSites()
{
    return {
        type: ORDER_GET_SITES,
    }
}

function internalGotSites(result)
{
    return {
        type: ORDER_GOT_SITES,
        result
    }
}

export function orderGetSites()
{
    return function (dispatch) {

        dispatch(internalGetSites());

        return fetch(`${API_URL}/site/status`, {
            credentials: "include",
        })
            .then((response) =>
                {
                    if (!response.ok) throw new Error('/site/status failed');
                    return response.json();
                }
            )
            .then(result =>
                dispatch(internalGotSites(result))
            )
            .catch(error => {
                dispatch(internalGotSites(null));
                console.log(error);
            })

    }
}

//--

export function orderSelectSite(selectedSiteID)
{
    return {
        type: ORDER_SELECT_SITE,
        selectedSiteID
    }
}

//--

function internalGetGroups(siteID)
{
    return {
        type: ORDER_GET_GROUPS,
        siteID
    }
}

function internalGotGroups(result)
{
    return {
        type: ORDER_GOT_GROUPS,
        result
    }
}

export function orderGetGroups(siteID)
{
    return function (dispatch) {

        dispatch(internalGetGroups(siteID));

        return fetch(`${API_URL}/group/statusBySite?siteID=${siteID}`, {
            credentials: "include",
        })
            .then((response) =>
                {
                    if (!response.ok) throw new Error('/group/statusBySite failed');
                    return response.json();
                }
            )
            .then(result =>
                dispatch(internalGotGroups(result))
            )
            .catch(error => {
                dispatch(internalGotGroups(null));
                console.log(error);
            })

    }
}

//--

export function orderSelectGroup(selectedGroupID)
{
    return {
        type: ORDER_SELECT_GROUP,
        selectedGroupID
    }
}

//--

function internalGetMenuByGroup(siteID, groupID)
{
    return {
        type: ORDER_GET_MENU_BY_GROUP,
        siteID,
        groupID
    }
}

function internalGotMenuByGroup(result)
{
    return {
        type: ORDER_GOT_MENU_BY_GROUP,
        result
    }
}

export function orderGetMenuByGroup(siteID, groupID)
{
    return function (dispatch) {

        dispatch(internalGetMenuByGroup(siteID, groupID));

        return fetch(`${API_URL}/menu/getByGroup?siteID=${siteID}&groupID=${groupID}`, {
            credentials: "include",
        })
            .then((response) =>
                {
                    if (!response.ok) throw new Error('/group/getByGroup failed');
                    return response.json();
                }
            )
            .then(result =>
                dispatch(internalGotMenuByGroup(result))
            )
            .catch(error => {
                dispatch(internalGotMenuByGroup(null));
                console.log(error);
            })

    }
}

//--

export function orderSelectProduct(selectedProductID)
{
    return {
        type: ORDER_SELECT_PRODUCT,
        selectedProductID
    }
}

//--


export function orderSetComments(comments)
{
    return {
        type: ORDER_SET_COMMENTS,
        comments
    }
}

//--

export function orderReview()
{
    return {
        type: ORDER_REVIEW,
    }
}

//--


function internalOrderSubmitSuccess()
{
    return {
        type: ORDER_SUBMIT_SUCCESS,
    }
}

function internalOrderSubmitFail(error)
{
    return {
        type: ORDER_SUBMIT_FAIL,
        error
    }
}

function internalOrderSubmit(order)
{
    return {
        type: ORDER_SUBMIT,
        order
    }
}

export function orderSubmit(order)
{
    return function (dispatch) {

        dispatch(internalOrderSubmit(order));

        return fetch(`${API_URL}/order/submit`, {
            credentials: "include",
            method: 'POST',
            body: JSON.stringify(order)
        })
            .then((response) =>
                {
                    return response.json();
                }
            )
            .then(result =>
                {
                    if (result.error)
                    {
                        dispatch(internalOrderSubmitFail(result.error))
                    }
                    else
                    {
                        dispatch(internalOrderSubmitSuccess())
                    }

                }

            )
            .catch(result => {
                dispatch(internalOrderSubmitFail(result.error));
            })

    }
}


export function orderResetLogout()
{
    return {
        type: ORDER_RESET_LOGOUT
    }
}
