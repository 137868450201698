import React, { Component } from 'react';


import EmptyState from './EmptyState';
import {connect} from "react-redux";
import {withStyles} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import {Error, RemoveCircle} from "@material-ui/icons";
import {blueGrey, deepOrange, red} from "@material-ui/core/colors";
import Skeleton from "@material-ui/lab/Skeleton";
import {orderGetMenuByGroup, orderSelectProduct} from "../actions/OrderActions";
import CardMedia from "@material-ui/core/CardMedia";
import Chip from "@material-ui/core/Chip";
import Badge from "@material-ui/core/Badge";



const styles = theme => ({
    media: {
        height: 270,
    },
    chip: {
        backgroundColor: deepOrange["800"],
    },
    cardContent: {
        padding: '9px 12px',
    },
    [theme.breakpoints.up('sm')]: {
        cardContent: {
            minHeight: '160px'
        }
    },
    qtyRemainingBadge: {
        marginRight: '13px',
        marginTop: '-1px',
    },
    remainingText: {
        marginRight: '30px'
    }


});


class OrderSelectProduct extends Component {


    componentDidMount() {
        const { dispatch, order } = this.props;

        if (order && order.selectedSiteID && order.selectedGroupID)
        {
            dispatch(orderGetMenuByGroup(order.selectedSiteID, order.selectedGroupID));
        }
    }

    render() {

        //redux
        const {
            dispatch,
            user,
            order,
        } = this.props;

        //withStyles
        const { classes } = this.props;


        const handleProductClick = (e, productID) => {
            dispatch(orderSelectProduct(productID));
        };


        if (user) {

            if (order && order.menu)
            {
                if (order.menu.length > 0)
                {
                    return (
                        <Box display={'flex'} flexWrap={'wrap'} m={-1}>

                            {
                                order.menu.map((menuItem, idx) =>
                                    <Box key={menuItem.id} flexGrow={1} flexBasis={0} minWidth={'310px'} maxWidth={'433px'} m={1}>
                                        <Card>
                                            <CardActionArea onClick={(e) => handleProductClick(e, menuItem.productID)}
                                                            disabled={menuItem.remainingQty <= 0}>

                                                {menuItem.imageUrl &&
                                                <CardMedia
                                                    className={classes.media}
                                                    image={menuItem.imageUrl}
                                                    title={menuItem.productName}
                                                />
                                                }

                                                <CardContent className={classes.cardContent}>

                                                    <Typography variant="h5" component="h2">
                                                        <Box
                                                            color={menuItem.remainingQty <= 0 ? blueGrey["300"] : 'primary'}>
                                                            {menuItem.productName}
                                                        </Box>
                                                    </Typography>

                                                    {menuItem.productDescription &&
                                                    <Typography component={'div'}>
                                                        <Box
                                                            color={menuItem.remainingQty <= 0 ? blueGrey["300"] : 'initial'}>
                                                            {menuItem.productDescription}
                                                        </Box>
                                                    </Typography>
                                                    }

                                                    <Box display={'flex'} justifyContent={'space-between'}>
                                                        <Typography variant="h6" component="h2">
                                                            <Box
                                                                color={menuItem.remainingQty <= 0 ? blueGrey["300"] : 'initial'}>
                                                                &euro;{menuItem.price.toFixed(2)}
                                                            </Box>
                                                        </Typography>

                                                        {menuItem.remainingQty <= 0 &&
                                                        <Box display={'flex'} alignItems={'center'}>
                                                            <Box component={'span'} color={red["800"]}>
                                                                <RemoveCircle/>
                                                            </Box>
                                                        </Box>
                                                        }
                                                        {menuItem.remainingQty > 0 &&
                                                            <Box component={'span'}>
                                                                <Typography variant={'caption'} className={classes.remainingText}>
                                                                    Remaining
                                                                </Typography>
                                                                <Badge badgeContent={menuItem.remainingQty}
                                                                       classes={{
                                                                           anchorOriginTopRightRectangle: classes.qtyRemainingBadge
                                                                       }}
                                                                       color="primary">
                                                                    <></>
                                                                </Badge>
                                                            </Box>
                                                        }
                                                    </Box>



                                                    {menuItem.allergens && menuItem.allergens.length > 0 &&
                                                    <Box marginLeft={'-2px'} my={'2px'}>
                                                        {
                                                            menuItem.allergens.map(allergen =>
                                                                <Box key={allergen.id} mr={'3px'} my={1}
                                                                     component={'span'}>
                                                                    <Chip color={'primary'} className={classes.chip}
                                                                          size={'small'} label={allergen.name}
                                                                          icon={<Error/>}/>
                                                                </Box>
                                                            )
                                                        }
                                                    </Box>
                                                    }


                                                </CardContent>
                                            </CardActionArea>
                                        </Card>
                                    </Box>
                                )
                            }

                        </Box>
                    );
                }
                else {
                    return (
                        <EmptyState title={'No Items'} description={'Please check back later'} />
                    )
                }
            }
            else {
                const skeletons = [];
                for (let i = 0; i < 2; i++) {
                    skeletons.push(
                        <Box key={i} flexGrow={1} flexBasis={0} minWidth={'310px'} maxWidth={'433px'} m={2}>
                            <Skeleton variant={'rect'} height={280} />
                        </Box>
                    );
                }
                return (
                    <Box display={'flex'} flexWrap={'wrap'} m={-2}>
                        { skeletons }
                    </Box>
                )
            }

        }

        return (
            <EmptyState />
        );
    }


}

function mapStateToProps(state) {
    const { user, order } = state;
    return {
        user, order
    };
}

const OrderSelectProductWithStyles = withStyles(styles)(OrderSelectProduct);

export default connect(mapStateToProps)(OrderSelectProductWithStyles);