import { combineReducers } from 'redux'

import userReducer from "./UserReducer";
import walletReducer from "./WalletReducer";
import orderReducer from "./OrderReducer";
import staticMenuReducer from "./StaticMenuReducer";


export default combineReducers({
   user: userReducer,
   wallet: walletReducer,
   order: orderReducer,
   staticMenu: staticMenuReducer,
});

