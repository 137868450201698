import React, { Component } from 'react';


import EmptyState from './EmptyState';
import {connect} from "react-redux";
import {withStyles} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {orderSubmit} from "../actions/OrderActions";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";



const styles = theme => ({

    list: {
        width: '100%',
    },
    submitButton: {
        width: '100%',
        marginTop: '24px',
    }

});


class OrderReview extends Component {



    render() {

        //redux
        const {
            dispatch,
            user,
            order,
        } = this.props;

        //withStyles
        const { classes } = this.props;


        const handleSubmit = () => {
            dispatch(orderSubmit({
                siteID: order.selectedSiteID,
                productID: order.selectedProductID,
                comments: order.comments,
            }));
        };

        if (user) {

            if (order && order.selectedSiteID && order.selectedGroupID && order.selectedProductID)
            {
                let site = order.sites.find(x => x.id === order.selectedSiteID);
                let menuItem = order.menu.find(x => x.productID === order.selectedProductID);

                if (site && menuItem)
                {
                    return (

                        <Box mt={-1}>

                            <List className={classes.list}>

                                <ListItem>
                                    <ListItemText
                                        primary={'Site'}
                                        secondary={site.name}
                                    />
                                </ListItem>
                                <Divider/>
                                <ListItem>
                                    <ListItemText
                                        primary={'Meal'}
                                        secondary={
                                            <>
                                                <Typography component="span" display={'block'} variant="body2">{menuItem.productName}</Typography>
                                                <Typography component="span" display={'block'} variant="caption">{menuItem.productDescription}</Typography>
                                            </>
                                        }
                                    />
                                </ListItem>
                                <Divider/>
                                <ListItem>
                                    <ListItemText
                                        primary={'Price'}
                                        secondary={
                                            <>
                                                &euro;{ menuItem.price.toFixed(2) }
                                            </>
                                        }
                                    />
                                </ListItem>

                                {order.comments &&

                                    <>
                                        <Divider/>
                                        <ListItem>
                                            <ListItemText
                                                primary={'Special Requirements'}
                                                secondary={order.comments}
                                            />
                                        </ListItem>
                                    </>
                                }



                            </List>


                            <Button size={'large'} className={classes.submitButton} variant="contained" color="primary"
                                    onClick={handleSubmit}>
                                Submit Order
                            </Button>

                        </Box>

                    );

                }
            }

        }

        return (
            <EmptyState />
        );
    }


}

function mapStateToProps(state) {
    const { user, order } = state;
    return {
        user, order
    };
}

const OrderReviewWithStyles = withStyles(styles)(OrderReview);

export default connect(mapStateToProps)(OrderReviewWithStyles);