import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';


let Theme = createMuiTheme({
    palette: {
        primary: {
            main: '#009ad8',
        },
        secondary: {
            main: '#f6b236',
        },
    },
    status: {
        // danger: 'orange',
    },
    overrides: {

        //StyleSheetName: {
        //  ruleName: {
        //     //css
        //  }
        //}

        MuiDialogActions: {
            root: {
                padding: '24px',
            },
        },

        MuiCard: {
            root: {
                backgroundColor: '#f7fafb'
            }
        },

        MuiCardMedia: {
            root: {
                backgroundColor: '#e5e8ea'
            }
        },
    },
});

Theme = responsiveFontSizes(Theme);

export default Theme;