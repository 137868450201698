import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import EmptyState from './EmptyState';
import {connect} from "react-redux";
import {withStyles} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import MobileStepper from "@material-ui/core/MobileStepper";
import Button from "@material-ui/core/Button";
import {orderStepBack} from "../actions/OrderActions";
import {ORDER_TOTAL_STEPS} from "../reducers/OrderReducer";
import OrderSelectSite from "./OrderSelectSite";
import OrderSelectGroup from "./OrderSelectGroup";
import {ArrowBackIos} from "@material-ui/icons";
import {grey} from "@material-ui/core/colors";
import OrderSelectProduct from "./OrderSelectProduct";
import OrderComments from "./OrderComments";
import OrderReview from "./OrderReview";
import OrderStatus from "./OrderStatus";
import {MSG_NOT_LOGGED_IN} from "../settings";

const styles = theme => ({
    title: {
        textTransform: 'uppercase',
    },
    backButton: {
        padding: '0px 3px'
    }
});

const orderSteps = [
    {
        title: 'Select site',
        component: <OrderSelectSite />,
    },
    {
        title: 'What would you like?',
        component: <OrderSelectGroup />,
    },
    {
        title: 'Select your meal',
        component: <OrderSelectProduct />,
    },
    {
        title: 'Special Requirements',
        component: <OrderComments />,
    },
    {
        title: 'Order review',
        component: <OrderReview />,
    },
];

class ScreenWallet extends Component {


    componentDidMount() {

    }

    render() {

        //redux
        const {
            dispatch,
            user,
            order,
        } = this.props;

        //withStyles
        const { classes } = this.props;


        // const handleNext = () => {
        //     dispatch(orderStepNext());
        // };
        //
        const handleBack = () => {
            dispatch(orderStepBack());
        };

        if (user) {

            if (order.status)
            {
                return (
                    <OrderStatus />
                )
            }

            //during order
            return (
                <>

                    <Box py={1}>

                        <Box mt={1}>
                            <Typography component={'div'} variant={'h6'} className={classes.title}>
                                {orderSteps[order.currentStep].title}
                            </Typography>
                        </Box>

                        <Box my={1}>
                            <MobileStepper
                                steps={ORDER_TOTAL_STEPS}
                                position="static"
                                variant="dots"
                                activeStep={order.currentStep}
                                nextButton={<Button size="small"> </Button>}
                                backButton={
                                    <Button size="small" className={classes.backButton} onClick={handleBack} disabled={!order.isBackEnabled}>
                                        { order.isBackEnabled &&
                                        <Box display={'flex'} alignItems={'center'} color={grey["600"]}>
                                            <ArrowBackIos />
                                            <Typography variant={'button'} component={'span'}>Back</Typography>
                                        </Box>
                                        }
                                    </Button>
                                }
                            />
                        </Box>

                        <Box my={3}>
                            {orderSteps[order.currentStep].component}
                        </Box>

                    </Box>


                </>
            );
        }

        return (
            <EmptyState description={MSG_NOT_LOGGED_IN} />
        );
    }


}

ScreenWallet.propTypes = {
    user: PropTypes.object
};

function mapStateToProps(state) {
    const { user, order } = state;
    return {
        user, order
    };
}

const ScreenWalletWithStyles = withStyles(styles)(ScreenWallet);

const ScreenWalletWithRouter = withRouter(ScreenWalletWithStyles);

export default connect(mapStateToProps)(ScreenWalletWithRouter);