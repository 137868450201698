import React, { Component } from 'react';


import EmptyState from './EmptyState';
import {connect} from "react-redux";
import {withStyles} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {orderReview, orderSetComments} from "../actions/OrderActions";



const styles = theme => ({

    comments: {
        width: '100%',
    },
    reviewButton: {
        width: '100%',
        marginTop: '24px',
    }

});


class OrderComments extends Component {

    constructor(props)
    {
        super(props);

        this.handleCommentChange = this.handleCommentChange.bind(this);
    }

    handleCommentChange(event) {

        const { dispatch } = this.props;
        dispatch(orderSetComments(event.target.value));
    }

    render() {

        //redux
        const {
            dispatch,
            user,
            order,
        } = this.props;

        //withStyles
        const { classes } = this.props;


        const handleReviewClick = () => {
            dispatch(orderReview());
        };

        if (user) {

            if (order && order.selectedProductID)
            {
                return (

                    <>

                        <TextField value={order.comments} onChange={this.handleCommentChange} multiline className={classes.comments} label="Brief Comments" variant="outlined" />

                        <Button size={'large'} className={classes.reviewButton} variant="outlined" color="primary" onClick={handleReviewClick}>
                            Review Order
                        </Button>

                    </>

                );
            }

        }

        return (
            <EmptyState />
        );
    }


}

function mapStateToProps(state) {
    const { user, order } = state;
    return {
        user, order
    };
}

const OrderCommentsWithStyles = withStyles(styles)(OrderComments);

export default connect(mapStateToProps)(OrderCommentsWithStyles);