import {
    WALLET_GOT_INFO,
    WALLET_GOT_TRANSACTIONS,
    WALLET_RESET_LOGOUT
} from "../actions/WalletActions";


const initialState = {
    walletBalance: null,
    totalTransactions: 999,
    transactions: []
};

export default function walletReducer(state = initialState, action) {
    switch (action.type)
    {
        case WALLET_GOT_INFO:
            // action.walletInfo contains {walletBalance}
            return Object.assign({}, state, action.walletInfo);

        case WALLET_GOT_TRANSACTIONS:
            // action.result contains {rowsTotal, startIndex, stopIndex, data}
            if (action.result.startIndex !== null
                && action.result.stopIndex !== null)
            {
                //immutable
                const newState = {
                    totalTransactions: action.result.rowsTotal,
                    transactions: [...state.transactions] //clone old list
                };

                //splice action.result.data into newState.transactions
                let returnedCount = action.result.stopIndex - action.result.startIndex + 1;
                newState.transactions.splice(action.result.startIndex, returnedCount, ...action.result.data);

                return Object.assign({}, state, newState);
            }
            return state; //unchanged


        case WALLET_RESET_LOGOUT:
            return Object.assign({}, state, initialState);

        default:
            return state;  //unchanged

    }
}