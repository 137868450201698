import {API_URL} from "../settings";

export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_WHOAMI = 'AUTH_WHOAMI';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_BYE = 'AUTH_BYE';
export const AUTH_FORGOT_PWD_REQUEST = 'AUTH_FORGOT_PWD_REQUEST';
export const AUTH_FORGOT_PWD_RESPONSE = 'AUTH_FORGOT_PWD_RESPONSE';
export const AUTH_RESET_PWD_REQUEST = 'AUTH_RESET_PWD_REQUEST';
export const AUTH_RESET_PWD_RESPONSE = 'AUTH_RESET_PWD_RESPONSE';

function internalAuthSuccess(user)
{
    return {
        type: AUTH_SUCCESS,
        user
    }
}

function internalAuthFail()
{
    return {
        type: AUTH_FAIL,
    }
}

function internalAuthLogin(email)
{
    return {
        type: AUTH_LOGIN,
        email
    }
}

export function authLogin(email, password)
{
    return function (dispatch) {

        dispatch(internalAuthLogin(email));

        return fetch(`${API_URL}/auth/login`, {
            credentials: "include",
            method: 'POST',
            body: JSON.stringify({
                email,
                password
            })
        })
            .then((response) =>
                {
                    if (!response.ok) throw new Error('login denied');
                    return response.json();
                }
            )
            .then(user =>
                dispatch(internalAuthSuccess({
                    displayName: user.displayName,
                    isEnableTopup: user.isEnableTopup
                }))
            )
            .catch(() => {
                dispatch(internalAuthFail());
            })

    }
}

//--

function internalWhoAmI(email)
{
    return {
        type: AUTH_WHOAMI,
        email
    }
}

export function authWhoAmI() {
    return function (dispatch) {

        dispatch(internalWhoAmI());

        return fetch(`${API_URL}/auth/whoami`,
            {
                credentials: "include",
            })
            .then((response) =>
                {
                    if (!response.ok) {
                        console.log(response);
                        throw new Error('whoami failed');
                    }
                    return response.json();
                }
            )
            .then(user =>
                dispatch(internalAuthSuccess({
                    displayName: user.displayName,
                    isEnableTopup: user.isEnableTopup
                }))
            )
            .catch(error => {
                dispatch(internalAuthFail());
            })

    }
}

//--


function internalAuthLogout()
{
    return {
        type: AUTH_LOGOUT
    }
}

function internalAuthBye()
{
    return {
        type: AUTH_BYE,
    }
}

export function authLogout()
{
    return function (dispatch) {

        dispatch(internalAuthLogout());

        return fetch(`${API_URL}/auth/logout`,
            {
                credentials: "include",
            })
            .then((response) =>
                {
                    if (!response.ok) {
                        console.log(response);
                        throw new Error('logout failed');
                    }
                    return response.json();
                }
            )
            .then(user =>
                dispatch(internalAuthBye())
            )
            .catch(error => {
                dispatch(internalAuthBye())
            })

    }
}

//--

function internalForgotPwdRequest(email)
{
    return {
        type: AUTH_FORGOT_PWD_REQUEST,
        email
    }
}

function internalForgotPwdResponse(response)
{
    return {
        type: AUTH_FORGOT_PWD_RESPONSE,
        response
    }
}

export function authForgotPwd(email)
{
    return function (dispatch) {

        dispatch(internalForgotPwdRequest(email));

        const formData = new FormData();
        formData.append('email', email);

        return fetch(`${API_URL}/auth/forgotPwd`, {
            method: 'POST',
            body: formData
        })
            .then((response) =>
                {
                    return response.json();
                }
            )
            .then(result =>
                {
                    dispatch(internalForgotPwdResponse(result))
                }

            )
            .catch(result => {
                dispatch(internalForgotPwdResponse(null));
            })

    }
}


//--

function internalResetPwdRequest()
{
    return {
        type: AUTH_RESET_PWD_REQUEST,
    }
}

function internalResetPwdResponse(response)
{
    return {
        type: AUTH_RESET_PWD_RESPONSE,
        response
    }
}

export function authResetPwd(token, password)
{
    return function (dispatch) {

        dispatch(internalResetPwdRequest());

        const formData = new FormData();
        formData.append('token', token);
        formData.append('password', password);

        return fetch(`${API_URL}/auth/resetPwd`, {
            method: 'POST',
            body: formData
        })
            .then((response) =>
                {
                    return response.json();
                }
            )
            .then(result =>
                {
                    dispatch(internalResetPwdResponse(result));
                    return result;
                }

            )
            .catch(result => {
                dispatch(internalResetPwdResponse(null));
            })

    }
}