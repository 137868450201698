import * as React from 'react';
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import {grey} from "@material-ui/core/colors";



export function WalletTransaction({date, heading, detail, amount, style}) {
    return (
        <Box display={'flex'} borderBottom={1} color={grey["A100"]} style={style} alignItems={'center'}>
            <Box display={'flex'} mt={1} flexGrow={1} flexDirection={'column'}  color={grey["600"]}>
                <Typography component={'div'} variant="caption">
                    {date}
                </Typography>
                <Typography component={'div'} variant="h5">
                    <Box lineHeight={1} color={grey["900"]} fontSize={'1.15rem'}>
                        {heading}
                    </Box>
                </Typography>
                <Typography component={'div'} variant="caption">
                    {detail}
                </Typography>

            </Box>
            <Box color={grey["900"]}>
                <Typography component={'div'} variant="h5">
                    {amount}
                </Typography>
            </Box>
        </Box>
    );
}

