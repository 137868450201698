import React, { Component } from 'react';

import PropTypes from 'prop-types';
import {Form, withFormik} from 'formik';
import * as Yup from 'yup';
import { withStyles } from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import {connect} from "react-redux";
import {authForgotPwd, authLogin} from "../actions/AuthActions";
import {walletGetInfo, walletGetTransactions} from "../actions/WalletActions";
import {orderGetGroups, orderGetSites} from "../actions/OrderActions";



const styles = (theme) => ({
    icon: {
        marginRight: theme.spacing(0.5)
    },

    divider: {
        margin: 'auto'
    },

    grid: {
        marginBottom: theme.spacing(2)
    }
});




class LoginDialog extends Component {

    resetPassword = () => {

        const {
            dispatch,
            openSnackbar
        } = this.props;

        //formik
        const {
            values
        } = this.props;

        dispatch(authForgotPwd(values.email))
            .then(result => {
                openSnackbar('Check your email for instructions to reset password');
            });

    };

    render() {

        //dialog
        const {
            dialogProps,
        } = this.props;

        //formik
        const {
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
        } = this.props;

        return (

            <Dialog fullWidth maxWidth="sm" {...dialogProps} onExited={this.handleExited}>

                <Form onSubmit={handleSubmit}>

                    <DialogTitle>
                        Login to your account
                    </DialogTitle>

                    <DialogContent>

                        <Grid container direction="column" spacing={2}>
                            <Grid item xs>
                                <TextField
                                    id={'email'}
                                    name={'email'}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.email}
                                    autoComplete="email"
                                    disabled={isSubmitting}
                                    error={!!(touched.email && errors.email)}
                                    fullWidth
                                    helperText={(touched.email && errors.email) ? errors.email : ''}
                                    label="E-mail Address"
                                    placeholder="your.email@gov.mt"
                                    variant="outlined"
                                />
                            </Grid>

                            <Grid item xs>
                                <TextField
                                    id={'password'}
                                    name={'password'}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.password}
                                    autoComplete="current-password"
                                    disabled={isSubmitting}
                                    error={!!(touched.password && errors.password)}
                                    fullWidth
                                    helperText={(touched.password && errors.password) ? errors.password : ''}
                                    label="Password"
                                    placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
                                    type="password"
                                    variant="outlined"
                                />
                            </Grid>
                        </Grid>

                    </DialogContent>

                    <DialogActions>
                        <Button color="secondary" onClick={dialogProps.onClose}>Cancel</Button>

                        <Button
                            color="primary"
                            disabled={!values.email || isSubmitting}
                            variant="outlined"
                            onClick={this.resetPassword}
                        >
                            Reset password
                        </Button>

                        <Button
                            type={'submit'}
                            color="primary"
                            disabled={isSubmitting}
                            variant="contained"
                        >
                            Sign in
                        </Button>
                    </DialogActions>

                </Form>
            </Dialog>

        );
    }
}

LoginDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    dialogProps: PropTypes.object.isRequired,
    openSnackbar: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired
};

const LoginDialogWithFormik = withFormik({
    mapPropsToValues: () => ({
        email: '',
        password: ''
    }),

    validationSchema: Yup.object({
        email: Yup.string()
            .email('Invalid Email')
            .required('Required'),
        password: Yup.string()
            .min(3, 'Too Short')
            .max(50, 'Too Long')
            .required('Required'),
    }),

    handleSubmit: (values, { props, setSubmitting }) => {

        const {
            dispatch,
            dialogProps,
            openSnackbar
        } = props;

        dispatch(authLogin(values.email, values.password))
            .then(result => {
                if (result)
                {
                    dialogProps.onClose(() =>
                    {
                        openSnackbar(`Signed in as ${result.user.displayName}`);

                        //preload some content
                        dispatch(orderGetSites());
                        dispatch(orderGetGroups(1));

                        //get user related info
                        dispatch(walletGetInfo());
                        dispatch(walletGetTransactions(0, 25));

                    });
                }
                else
                {
                    openSnackbar('Login Failed');
                }
            })
            .finally(() => {
                setSubmitting(false);
            });

    },
})(LoginDialog);

const LoginDialogWithStyles = withStyles(styles)(LoginDialogWithFormik);

function mapStateToProps(state) {
    const { user } = state;
    return {
        user
    }
}

export default connect(mapStateToProps)(LoginDialogWithStyles);

