import * as PropTypes from "prop-types";
import React, {Component} from "react";
import {Container, withStyles} from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {AccountBalanceWallet, MenuBook, RestaurantMenu} from "@material-ui/icons";
import {withRouter} from "react-router-dom";
import Box from "@material-ui/core/Box";


const styles = theme => ({
    root: {
        position: 'fixed',
        top: '63px',
        width: '100%',
    },
    tabs: {
        backgroundColor: theme.palette.background.default
    },
    containerSpacing: {
        height: '10px',
        backgroundColor: theme.palette.background.paper
    }

});

class DesktopTabs extends Component {

    handleChange = (event, value) => {

        //withRouter
        const { history } = this.props;

        history.push(value);

    };

    render() {

        //withStyles
        const { classes } = this.props;

        //withRouter
        const { location } = this.props;

        return (
            <Box className={classes.root}>

                <Tabs
                    value={location.pathname}
                    onChange={this.handleChange}
                    scrollButtons="on"
                    indicatorColor="primary"
                    textColor="primary"
                    centered
                    className={classes.tabs}
                >
                    <Tab label="Menu" value={'/menu'} icon={<MenuBook />} />
                    <Tab label="Wallet" value={'/'} icon={<AccountBalanceWallet />} />
                    <Tab label="Order" value={'/order'} icon={<RestaurantMenu />} />
                </Tabs>

                <Container maxWidth={'md'} className={classes.containerSpacing}>
                    <span/>
                </Container>

            </Box>
        );
    }
}

DesktopTabs.propTypes = {
    classes: PropTypes.object.isRequired,
};

const DesktopTabsWithStyles = withStyles(styles)(DesktopTabs);

export default withRouter(DesktopTabsWithStyles) ;