import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { BrowserRouter, Switch, Route } from "react-router-dom";
import NotFoundContent from './NotFoundContent';
import ScreenMenu from "./ScreenMenu";
import {Container, Hidden, withStyles} from "@material-ui/core";
import DesktopTabs from "./DesktopTabs";
import MobileBottomNavigation from "./MobileBottomNavigation";
import ScreenWallet from "./ScreenWallet";
import Box from "@material-ui/core/Box";
import ScreenOrder from "./ScreenOrder";
import {connect} from "react-redux";
import ScreenResetPwd from "./ScreenResetPwd";

const styles = theme => ({
    screensContainer: {
        backgroundColor: '#fff',
        [theme.breakpoints.up('sm')]: {
            marginTop: '145px',
            marginBottom: '15px',
        },
        [theme.breakpoints.down('xs')]: {
            marginTop: '50px',
            paddingTop: '6px',
            marginBottom: '60px',
        },
    },
});

class Router extends Component {


    render() {


        //app
        const {
            openSnackbar,
            openDialog
        } = this.props;

        //withStyles
        const { classes } = this.props;

        //redux
        const { user } = this.props;

        return (

            <BrowserRouter basename={process.env.REACT_APP_BASENAME}>

                <Container maxWidth={'md'} className={classes.screensContainer}>
                    <Box p={1}>
                        <Switch>
                            <Route path="/resetPwd/:token" exact>
                                <ScreenResetPwd
                                    openSnackbar={openSnackbar}
                                    openDialog={openDialog}
                                />
                            </Route>

                            <Route path="/menu" exact>
                                <ScreenMenu />
                            </Route>

                            <Route path="/order" exact>
                                <ScreenOrder />
                            </Route>

                            <Route path="/" exact>
                                <ScreenWallet />
                            </Route>

                            <Route>
                                <NotFoundContent />
                            </Route>
                        </Switch>
                    </Box>
                </Container>

                {user &&
                <>
                    <Hidden xsDown>
                        <DesktopTabs/>
                    </Hidden>

                    <Hidden smUp>
                        <MobileBottomNavigation/>
                    </Hidden>
                </>
                }

            </BrowserRouter>
        )
    }
}

Router.propTypes = {
    // Properties
    user: PropTypes.object,

    // Functions
    openSnackbar: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    const { user } = state;
    return {
        user
    };
}

let RouterWithStyles =  withStyles(styles)(Router);

export default connect(mapStateToProps)(RouterWithStyles);