import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import {AccountBalanceWallet, MenuBook, RestaurantMenu} from "@material-ui/icons";
import {withRouter} from "react-router-dom";

const styles = theme => ({
    bottomNavigation: {
        width: '100%',
        position: 'fixed',
        bottom: 0,
        backgroundColor: theme.palette.background.default,
    },
});

class MobileBottomNavigation extends React.Component {

    handleChange = (event, value) => {

        //withRouter
        const { history } = this.props;

        history.push(value);

    };

    render() {

        //withStyles
        const { classes } = this.props;

        //withRouter
        const { location } = this.props;

        return (
            <BottomNavigation
                value={location.pathname}
                onChange={this.handleChange}
                showLabels
                className={classes.bottomNavigation}
            >
                <BottomNavigationAction label="Menu" value={'/menu'} icon={<MenuBook />} />
                <BottomNavigationAction label="Wallet" value={'/'} icon={<AccountBalanceWallet />} />
                <BottomNavigationAction label="Order" value={'/order'} icon={<RestaurantMenu />} />
            </BottomNavigation>
        );
    }
}

MobileBottomNavigation.propTypes = {
    classes: PropTypes.object.isRequired,
};

const MobileBottomNavigationWithStyles = withStyles(styles)(MobileBottomNavigation);

export default withRouter(MobileBottomNavigationWithStyles) ;